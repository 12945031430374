<template xmlns="http://www.w3.org/1999/html">
  <div id="property-facilities">

    <vs-row class="mb-4">
      <vs-col vs-lg="6" vs-md="6" vs-xs="12">
        <div class="vx-card p-6">
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                    v-model="search" placeholder="Search..." />
        </div>
      </vs-col>
    </vs-row>


    <el-row v-if="!loading" :gutter="10">
      <el-col :span="12" :md="8" class="mb-8"
              v-for="(facilities, groupName) in filteredFac"
              :key="'fac' + groupName">
        <div class="vx-card p-6">
          <h3 class="text-xl font-semibold mb-4">
            {{ groupName }}
          </h3>
          <div v-for="facility of facilities" :key="facility.id"
               class="flex flex-wrap w-full justify-between items-center mt-3">
            <vs-checkbox :id="'check-' + facility.id"
                         v-model="facility.checked" @change="storeData">
              <h3 class="text-xl font-semibold mb-4">
                &nbsp;{{ facility.name }}
              </h3>
            </vs-checkbox>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  name: "PropertyFacilities",
  metaInfo: {
    title: "Facilities & Services",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      facilities: [],
      groupedFac: {},
      payload: {
        features: []
      },
      loading: true,
      search: '',
    };
  },
  computed: {
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },
    filteredFac() {
      if (this.search.length > 2) {
        const result = {};
        for (const [key, val] of Object.entries(this.groupedFac)) {
          if (!result[key]) result[key] = [];
          result[key] = val.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));
        }
        return result;
      }
      return this.groupedFac;
    }
  },
  methods: {
    async getFacilities() {
      try {
        const { data: res } = await axios.get('/facilities');
        this.facilities = res.data;
        for (const fac of this.facilities) {
          fac.checked = this.payload.features.includes(fac.id);
        }
        for (const facility of this.facilities) {
          const { id, category, name, checked } = facility;
          if(!this.groupedFac[category]) {
            this.groupedFac[category] = [];
          }
          const obj = { id, name, checked };
          this.groupedFac[category].push(obj);
        }
        this.loading = false;
        console.log(this.groupedFac);
      } catch (e) {
        this.$catchErrorResponse(e)
      }
    },
    async getData() {
      try {
        const { data: res } = await axios.get(`/hotels/${this.hotelId}`);
        this.payload.features = res.data.facilities.length > 0 ? res.data.facilities.map(feature => feature.id) : [];
      } catch (e) {
        this.$catchErrorResponse(e);
      }
    },
    async storeData () {
      try {
        const payload = {
          facilitiesIds: [],
        }
        for (const [key, val] of Object.entries(this.groupedFac)) {
          const ids = val.filter(x => x.checked).map(x => x.id);
          payload.facilitiesIds.push(...ids);
        }
        await axios.put(`/hotels/${this.hotelId}/facilities`, payload)
        this.$vs.notify({
          title: "Success",
          text: 'Update facilities success',
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success"
        });
      } catch (e) {
        this.$catchErrorResponse(e)
      }
    }
  },
  async mounted() {
    await this.getData();
    await this.getFacilities();
  }
};
</script>

<style scoped>
#property-facilities .con-vs-radio,
#property-facilities .con-vs-checkbox {
  display: flex !important;
  align-items: flex-start !important;
}
</style>
